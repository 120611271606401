<template lang="pug">
div(:class='backgroundClass')
  div(class='py-2 px-4 flex justify-between items-center' v-bind='buttonProperties' v-on='buttonEvents')
    template(v-for='widget in data.header')
      slot(:data='widget')
    span(v-if='isEnabled' class='ml-2' :class='isActive ? "text-lg" : "text-xl"') {{ isActive ? '&ndash;' : '+' }}
  transition(
    enter-active-class='transition-opacity ease-out duration-200'
    enter-from-class='opacity-0'
    leave-active-class='transition-opacity ease-in duration-200'
    leave-to-class='opacity-0'
  )
    div(v-show='isActive' class='py-2 px-4' v-bind='regionProperties')
      template(v-for='widget in data.body')
        slot(:data='widget')
</template>

<script setup lang="ts">
const { $storyblok, $device } = useNuxtApp()

interface AccordianWidget extends Widget {
  backgroundColor: BackgroundColor
  body: string
  header: string
  mobileIsEnabled: StringBoolean
  tabletIsEnabled: StringBoolean
  desktopIsEnabled: StringBoolean
}

const { data } = defineProps<{
  data: AccordianWidget
}>()

const isOpen: Ref<boolean> = ref(false)

const backgroundClass = computed(() => {
  if (!isEnabled.value) return
  return generateBackgroundColorClass(data.backgroundColor)
})

const buttonEvents = computed(() => {
  if (!isEnabled.value) return {}
  return {
    click: toggle,
    keyup: (e: any) => {
      // Cannot dynamically apply modifiers so we need to reject anything other than the Enter key ourselves
      if (e.key !== 'Enter') return
      // Allows a user to Tab to the Accordion and open it with the Enter Key.
      toggle()
    },
  }
})

const buttonProperties = computed(() => {
  if (!isEnabled.value) return
  return {
    id: buttonId.value,
    role: 'button',
    tabindex: 0,
    'aria-pressed': isActive.value,
    'aria-control': regionId.value,
  }
})

const regionProperties = computed(() => {
  if (!isEnabled.value) return
  return {
    id: regionId.value,
    role: 'region',
    'aria-labelledby': buttonId.value,
  }
})

const isEnabled = computed(() => {
  // Handle cascade of Inherit Rules
  const [mobileIsEnabled, tabletIsEnabled, desktopIsEnabled] = $storyblok.handleInheritedBool(
    data.mobileIsEnabled,
    data.tabletIsEnabled,
    data.desktopIsEnabled
  )
  // Return based on device
  if ($device.value.isMobile) return mobileIsEnabled
  if ($device.value.isSmall) return tabletIsEnabled
  return desktopIsEnabled
})

// If it is Disabled it should render open
if (!isEnabled.value) isOpen.value = true

watch(isEnabled, (newVal, oldVal) => {
  if (newVal === oldVal) return

  // If it is being enabled, close the accordion
  if (newVal) {
    isOpen.value = false
    return
  }
  // If it is disabled open it
  isOpen.value = true
})

const buttonId = computed(() => {
  return `accordion_button_${data._uid}`
})

const regionId = computed(() => {
  return `accordion_region_${data._uid}`
})

// While editing we need to make sure the accordion stays open so we don't hide the content
const isActive = computed(() => {
  return $storyblok.isEditing.value || isOpen.value
})

function toggle() {
  if (!isEnabled.value || $storyblok.isEditing.value) return
  isOpen.value = !isOpen.value
}
</script>
